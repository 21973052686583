<template>
  <ModelDialog v-model="visible" :title="title" @ok="handleSubmit" @cancel="visible = false" fullscreen>
    <div class="step-item-wrap">
      <div class="processscheme-wrap pa-3">
        <ProcessSchemeNodeList
          v-if="entity.ProcessScheme"
          isRoot
          v-model="entity.ProcessScheme"
          :selected="selected"
        ></ProcessSchemeNodeList>
        <div class="end-node">
          <div class="end-node-circle"></div>
          <div class="end-node-text grey--text fs-md mt-1">结束</div>
        </div>
      </div>
    </div>
  </ModelDialog>
</template>

<script>
import { normalize, stringify } from './ProcessScheme'
import ProcessSchemeNodeList from './components/ProcessSchemeNodeList'
// var uuid = require('node-uuid')
export default {
  components: { ProcessSchemeNodeList },
  props: {
    parentObj: Object,
  },
  data() {
    return {
      visible: false,
      entity: {},
      title: '工艺设定',
    }
  },
  computed: {
    selected() {
      return stringify(this.entity.ProcessScheme || [])
    },
  },
  methods: {
    init() {
      this.visible = true
      this.entity = {}
    },
    openForm(id) {
      this.init()

      if (id) {
        this.loading = true
        this.$http.post('/Main/product/GetTheData', { id: id }).then(({ Data }) => {
          this.loading = false
          try {
            Data.ProcessScheme = normalize(Data.ProcessScheme ? JSON.parse(Data.ProcessScheme) : [])
            // this.selected = Data.ProcessScheme.map(o => o.Id)
          } catch (e) {
            console.log(e)
            Data.ProcessScheme = []
          }
          this.entity = Data
        })
      }
    },
    handleSubmit() {
      const str = JSON.stringify(stringify(this.entity.ProcessScheme))
      console.log(stringify(this.entity.ProcessScheme))
      this.$runAsync(
        this.$ajax.post('/Main/product/SaveData', Object.assign({}, this.entity, { ProcessScheme: str })).then(() => {
          this.$message.success('操作成功!')

          this.parentObj.getDataList()
          this.visible = false
        })
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.step-item-wrap {
  height: 100%;
  overflow: auto;
  .processscheme-wrap {
    background: #f5f5f7;
    min-width: min-content;
  }
}
.end-node {
  .end-node-circle {
    width: 10px;
    height: 10px;
    margin: auto;
    border-radius: 50%;
    background: #dbdcdc;
  }
  .end-node-text {
    margin-top: 5px;
    text-align: center;
  }
}
</style>
